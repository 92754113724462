import {checkValid} from "../../../globalModels/functions/ModelsValidate";


export function FastOrders() {

    this.checkValid = checkValid

    this.data = {
        id: '',
        comment: '',
        status: null,
        createdAt: null,
        relatedOrders: [],
        ratesListSelected: null,
    }

    this.validation = {
        id: false,
        comment: false,
        status: false,
        ratesListSelected: false,
    }

    this.validationTranslate = {
        id: '',
        comment: '',
        status: '',
        ratesListSelected: '',
    }

    this.validationTxt = {
        id: false,
        comment: false,
        status: false,
        ratesListSelected: false,
    }

    /**
     * Getters
     */
    this.getId = () => {
        return this.data.id
    }
    this.getComment = () => {
        return this.data.comment
    }
    this.getStatus = () => {
        return this.data.status
    }
    this.getCreatedAt = () => {
        return this.data.createdAt
    }
    this.getSendAt = () => {
        return this.data.sendAt
    }
    this.getRelatedOrders = () => {
        return this.data.relatedOrders
    }
    this.getRatesListSelected = () => {
        return this.data.ratesListSelected
    }

    /**
     * Setters
     */
    this.setId = (val) => {
        this.data.id = val
    }
    this.setComment = (val) => {
        this.data.comment = val
    }
    this.setStatus = (val) => {
        this.data.status = val
    }
    this.setCreatedAt = (val) => {
        this.data.createdAt = val
    }
    this.setRelatedOrders = (val) => {
        this.data.relatedOrders = val
    }
    this.setRatesListSelected = (val) => {
        this.data.ratesListSelected = val
    }
}

/**
 * Global Setters
 */
FastOrders.prototype.setFastOrdersItem = function (item) {
    this.setId(item?.id)
    this.setComment(item?.comment)
    this.setStatus(item?.status)
    this.setRatesListSelected(item?.rate)

    if (item.fast_order_elements && item.fast_order_elements.length) {
        this.setRelatedOrders(item.fast_order_elements.map(orderItem => {

            if (orderItem?.rate && orderItem?.price_variant?.rate.length) {
                orderItem.selected_rate = orderItem.price_variant.rate.find(findItem => {
                    return findItem.driver_delivery_method_id === orderItem.rate.driver_delivery_method_id &&
                        findItem.shipmentCost === orderItem.rate.shipmentCost
                })
            }
            else if (orderItem?.price_variant?.rate.length) {
                orderItem.selected_rate = orderItem.price_variant.rate[0]
            }

            return orderItem
        }))
    }
}

/**
 * Functions
 */

FastOrders.prototype.addRelatedOrders = function (val) {
    this.data.relatedOrders.push(val)
}

FastOrders.prototype.removeRelatedOrders = function (val) {
    this.data.relatedOrders.splice(val, 1)
}

/**
 * Prepare Data
 */

FastOrders.prototype.prepareSave = function () {


    let preparedData = {
        'rate': this.getRatesListSelected(),
    }

    if (this.getRelatedOrders().length > 0) {
        preparedData['fast_order_elements'] = this.getRelatedOrders().map(item => {
            let data = {
                'order_id': item.order_id,
                'element_type': item.element_type,
                'element_select': item.element_select,
            }
            if(item?.changed) {
                data ['full_weight_oz'] = item.full_weight_oz
            }
            return data
        })
    }

    console.log(preparedData);

    return preparedData
}

/**
 * Validations
 */
