<template>
  <div class="fragment">
    <div class="order-create__row custom-row">
      <div class="custom-col custom-col--66 custom-col--md-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['consolidationUnion_scanNumber'])"></div>
        <SearchInput
            class="scan-ico fast-order-search"
            :label="$t('consolidationUnion_scanNumber.localization_value.value')"
            :disabled="$store.getters.getFastOrdersBtn"
            v-model="scanValue"
            @submit="scan($event)"
            @keyup.native="scan($event, true)"
        />
        <div class="color-red pt-2"
             v-if="scanErrorItem !== ''"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['consolidationUnion_notFound'])"></div>
          {{"'" + scanErrorItem + "'"}} {{$t('consolidationUnion_notFound.localization_value.value')}}
        </div>
      </div>
    </div>

    <div class="order-create__row custom-row"
         v-if="ratesListOptions.length > 0">
      <div class="custom-col custom-col--33 custom-col--md-100">
        <DefaultSelect
            :options="ratesListOptions"
            :optionsLabel="'name'"
            :label="$t('fastOrders_variant.localization_value.value')"
            :selected="FO.data.ratesListSelected"
            :clearable="false"
            @change="(val) => changeRate(val)"
        />
      </div>
    </div>
    <div class="order-create__row custom-row">
      <div v-if="FO.getRelatedOrders().length > 0" class="custom-col">
        <div class="site-table-wrap table-small"
             v-if="!isMobileFunc()"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate([
              'fastOrders_name',
              'fastOrders_shippingCompany',
              'fastOrders_fullWeight',
              'fastOrders_size',
              'fastOrders_variant',
              'fastOrders_variants',
              'poshta_Label',
              'fastOrders_oldLabel',
              ])"></div>
          <table class="site-table">
            <thead>
            <tr>
              <th class="pr-0"></th>
              <th class="white-space-line">{{$t('fastOrders_name.localization_value.value')}}</th>
              <th class="white-space-line">{{$t('fastOrders_shippingCompany.localization_value.value')}}</th>
              <th class="white-space-line">{{$t('fastOrders_fullWeight.localization_value.value')}}</th>
              <th width="20%" class="white-space-line">{{$t('fastOrders_size.localization_value.value')}}</th>
              <th width="20%" class="white-space-line">{{$t('consolidationMeest_trackingNumber.localization_value.value')}}</th>
<!--              <th width="20%" class="white-space-line">{{$t('fastOrders_variants.localization_value.value')}}</th>-->
              <th align="right" class="pr-0"></th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in FO.getRelatedOrders()"
                :key="index"
            >
              <td class="pr-0">
                <DefaultCheckbox
                    class="checkbox-scoped"
                    :value="item.element_select"
                    @input="item.element_select = !item.element_select"
                />
              </td>
              <td>
                <div class="table-row">
                  {{item.name}}
                </div>
              </td>
              <td>
                <div class="table-row flex-column">
                  <ValueHelper
                      :value="item"
                      :deep="'shipping_company'"
                  />
                  <ValueHelper
                      :value="item"
                      :deep="'shipping_method'"
                  />
                </div>
              </td>
              <td>
                <div class="table-row">
<!--                  <ValueHelper-->
<!--                      :value="item"-->
<!--                      :deep="'full_weight_oz'"-->
<!--                  />-->
<!--                      v-bind:class="{'ui-no-valid': validation.name}"-->
<!--                      :error="validation.name"-->
<!--                      :errorTxt="serverError ? validationTxt.name : $t(`${validationTranslate.name}.localization_value.value`)"-->
                  <DefaultInput
                      :label="$t('fastOrders_fullWeight.localization_value.value')"
                      :type="'text'"
                      :value="item.full_weight_oz"
                      @input.native="acceptNumber($event, item)"
                  />
                </div>
              </td>
              <td>
                <div class="table-row">
                  <ValueHelper
                      :value="item"
                      :deep="'full_size_inch'"
                  />
                </div>
              </td>
              <td>
                <div class="table-row">
                  <ValueHelper
                      :value="item"
                      :deep="'tracking_number'"
                  />
                </div>
              </td>
<!--              <td>-->
<!--                <DefaultSelect-->
<!--                    v-if="item.price_variant.rate.length"-->
<!--                    :options="item.price_variant.rate"-->
<!--                    :optionsLabel="'name'"-->
<!--                    :label="$t('fastOrders_variant.localization_value.value')"-->
<!--                    @change="(val) => changeVariant(item, val)"-->
<!--                    :selected="item.selected_rate"-->
<!--                />-->
<!--                <template v-if="item.price_variant.errors.length">-->
<!--                  <div-->
<!--                      v-for="(errItem, errItemIndex) in item.price_variant.errors"-->
<!--                      :key="errItemIndex"-->
<!--                      class="color-red">-->
<!--                    {{errItem.mess}}-->
<!--                  </div>-->
<!--                </template>-->
<!--              </td>-->
              <td align="right" class="pr-0">
                <div class="table-right table-manage-list justify-content-end">
                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton" class="w160 right ">
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_edit.localization_value.value')"
                          :target="'_blank'"
                          :type="'edit'"
                          :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.order_id"
                      />
                    </template>
                    <template slot="item">
                      <LinkButton
                          :value="$t('common_delete.localization_value.value')"
                          :type="'delete'"
                          @click.native="removeRelatedOrder(index)"
                      />
                    </template>
                    <template
                        v-if="FO.getStatus() === FAST_ORDER_STATUS_SUCCESS.value && item.label"
                        slot="item">
                      <LinkButton
                          :value="$t('poshta_Label.localization_value.value')"
                          :type="'dog'"
                          @click.native="openLabelFBM(item.label)"
                      />
                    </template>
                    <template
                        v-if="FO.getStatus() === FAST_ORDER_STATUS_SUCCESS.value && getOldLabel(item)"
                        slot="item">
                      <LinkButton
                          :value="$t('fastOrders_oldLabel.localization_value.value')"
                          :type="'dog'"
                          @click.native="openLabelFBM(getOldLabel(item))"
                      />
                    </template>

                  </ManagerButton>
                </div>
              </td>
            </tr>
            </tbody>
          </table>

        </div>

        <div class="site-table-mobile"
             v-if="isMobileFunc()"
        >
          <div class="site-table-mobile__inner custom-row">
            <div class="custom-col custom-col--50 custom-col--sm-100"
                 v-for="(item, index) in FO.getRelatedOrders()"
                 :key="index"
            >
              <div class="site-table-mobile__item">
                <div class="site-table-mobile__head">
                  <div class="site-table-mobile__head-checkbox">
                    <DefaultCheckbox
                        class="checkbox-scoped"
                        :value="item.element_select"
                        @input="item.element_select = !item.element_select"
                    />
                  </div>
                  {{item.name}}
                </div>
                <div class="site-table-mobile__row custom-row">

                  <div class="site-table-mobile__piece custom-col custom-col--50">
                    {{$t('fastOrders_shippingCompany.localization_value.value')}}
                    <span>
                      <ValueHelper
                          :value="item"
                          :deep="'shipping_company'"
                      />
                      <ValueHelper
                          :value="item"
                          :deep="'shipping_method'"
                      />
                    </span>
                  </div>

                  <div class="site-table-mobile__piece custom-col custom-col--50">
                    {{$t('fastOrders_fullWeight.localization_value.value')}}
                    <span>
<!--                      <ValueHelper-->
<!--                          :value="item"-->
<!--                          :deep="'full_weight_oz'"-->
<!--                      />-->
                      <DefaultInput
                          :label="''"
                          :type="'text'"
                          :value="item.full_weight_oz"
                          @input.native="acceptNumber($event, item)"
                      />
                    </span>
                  </div>

                  <div class="site-table-mobile__piece custom-col custom-col--50">
                    {{$t('fastOrders_size.localization_value.value')}}
                    <span>
                      <ValueHelper
                          :value="item"
                          :deep="'full_size_inch'"
                      />
                    </span>
                  </div>

                  <div class="site-table-mobile__piece custom-col custom-col--50">
                    {{$t('consolidationMeest_trackingNumber.localization_value.value')}}
                    <span>
                      <ValueHelper
                          :value="item"
                          :deep="'tracking_number'"
                      />
                    </span>
                  </div>

<!--                  <div class="site-table-mobile__piece custom-col">-->
<!--                    {{$t('fastOrders_variants.localization_value.value')}}-->
<!--&lt;!&ndash;                    <DefaultSelect&ndash;&gt;-->
<!--&lt;!&ndash;                        v-if="item.price_variant.rate.length"&ndash;&gt;-->
<!--&lt;!&ndash;                        :options="item.price_variant.rate"&ndash;&gt;-->
<!--&lt;!&ndash;                        :optionsLabel="'name'"&ndash;&gt;-->
<!--&lt;!&ndash;                        :label="$t('fastOrders_variant.localization_value.value')"&ndash;&gt;-->
<!--&lt;!&ndash;                        @change="(val) => changeVariant(item, val)"&ndash;&gt;-->
<!--&lt;!&ndash;                        :selected="item.selected_rate"&ndash;&gt;-->
<!--&lt;!&ndash;                    />&ndash;&gt;-->
<!--                    <template v-if="item.price_variant.errors.length">-->
<!--                      <div-->
<!--                          v-for="(errItem, errItemIndex) in item.price_variant.errors"-->
<!--                          :key="errItemIndex"-->
<!--                          class="color-red">-->
<!--                        {{errItem.mess}}-->
<!--                      </div>-->
<!--                    </template>-->
<!--                  </div>-->

                  <div class="site-table-mobile__piece custom-col">
                    <div class="table-right table-manage-list">
                      <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton" class="mw-100">
                        <template slot="item">
                          <LinkButton
                              :value="$t('common_edit.localization_value.value')"
                              :target="'_blank'"
                              :type="'edit'"
                              :link="$store.getters.GET_PATHS.ordersFBM + '/' + getOrderFBMTypeById(item.order_type_id).name + '/' + item.id"
                          />
                        </template>
                        <template slot="item">
                          <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removeRelatedOrder(index)"
                          />
                        </template>
                        <template
                            v-if="FO.getStatus() === FAST_ORDER_STATUS_SUCCESS.value && item.label"
                            slot="item">
                          <LinkButton
                              :value="$t('poshta_Label.localization_value.value')"
                              :type="'dog'"
                              @click.native="openLabelFBM(item.label)"
                          />
                        </template>
                        <template
                            v-if="FO.getStatus() === FAST_ORDER_STATUS_SUCCESS.value && getOldLabel(item)"
                            slot="item">
                          <LinkButton
                              :value="$t('fastOrders_oldLabel.localization_value.value')"
                              :type="'dog'"
                              @click.native="openLabelFBM(getOldLabel(item))"
                          />
                        </template>
                      </ManagerButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
// import ManagerButton from "../../../../UI/buttons/ManagerButton/ManagerButton";
// import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
import {mixinDetictingMobile} from "../../../../../mixins/mobileFunctions";
import {FBMMixinsHelper} from "../../../../../mixins/FBMMixins/FBMMixins";
import SearchInput from "../../../../UI/inputs/SearchInput/SearchInput";
import DefaultCheckbox from "../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
import ManagerButton from "../../../../UI/buttons/ManagerButton/ManagerButton";
import LinkButton from "../../../../UI/buttons/LinkButton/LinkButton";
import ValueHelper from "../../../../coreComponents/ValueHelper/ValueHelper";
import DefaultSelect from "../../../../UI/selectiones/DefaultSelect/DefaultSelect";
import {FAST_ORDER_STATUS_SUCCESS} from "../../../../../staticData/staticVariables";
import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";
import {scaleMixins} from "@/mixins/scaleMixins/scaleMixin";

export default {
  name: "ScannerBlock",
  components: {
    DefaultInput,
    DefaultSelect,
    ValueHelper,
    LinkButton,
    ManagerButton,
    DefaultCheckbox,
    SearchInput,
    // LinkButton,
    // ManagerButton,
    // DefaultCheckbox,
  },

  mixins: [mixinDetictingMobile, FBMMixinsHelper, scaleMixins],

  props: {
    FO: Object,
    created: {
      type: Boolean,
      default: false
    },
  },

  data() {
    return {
      scanValue: '',
      scanErrorItem: '',
      FAST_ORDER_STATUS_SUCCESS: FAST_ORDER_STATUS_SUCCESS,
      ratesListOptions: [],
      foundDevice: false,
      weightScaleLB: 0,
      weightScaleOZ: 0,
    }
  },

  async mounted() {
    /**
     * Init scale device
     */
    if(this._.has(this.currentPermissions, this.PERMISSIONS.USER_CAN_USE_SCALES) && navigator.hid) {
      await navigator.hid.getDevices().then(async devices => {
        let device = this._.find(devices, {vendorId: 2338})
        if(!device) return
        this.foundDevice = true
        await device.open().then(() => {
          this.changeWeightScaleMixin(device)
        })
      })
    }

    this.$store.dispatch('getRatesList').then(response => {
      this.ratesListOptions = this.getRespData(response)
      if(this.created && this.ratesListOptions.length > 0) {
        this.FO.data.ratesListSelected = this.ratesListOptions[0]
      }
    })
  },

  methods: {


    addWeightScaleDevice() {
      const vendorId = 0x0922;
      navigator.hid.requestDevice({ filters: [{ vendorId }] })
          .then(devices => {
            if(devices.length > 0) {
              this.foundDevice = true
              devices.map(device => {
                return device.open()
                    .then(() => {
                      this.changeWeightScaleMixin(device)
                    })
              })
            }
          })
          .catch(error => {
            console.log(error);
          });
    },

    // changeWeight(val, item) {
    //   if(val === ' ' && this.foundDevice) return
    //
    //   item.changed = true
    //   item.full_weight_oz = val
    // },

    acceptNumber(event, item) {
      item.changed = true

      if((this._.has(this.currentPermissions, this.PERMISSIONS.USER_CAN_USE_SCALES)) &&
          this.foundDevice && event.target.value === ' ') {
        if(Number(this.weightScaleLB) > 0) {
          item.full_weight_oz = this.weightScaleLB
        }
        if(Number(this.weightScaleOZ) > 0) {
          item.full_weight_oz = this.weightScaleOZ
        }
        return
      }

      item.full_weight_oz = event.target.value.replace('.', '').replace(',', '')
    },

    changeRate(val) {
      this.FO.data.ratesListSelected = val
    },

    removeRelatedOrder(index) {
      this.FO.removeRelatedOrders(index)
    },

    scan(e, checkPressedKey = false) {

      if (checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') return

      this.scanErrorItem = ''
      let requestType = 'searchFastOrdersFBMItem'
      let requestData = {
        'term': this.scanValue,
      }

      this.$store.dispatch(requestType, requestData).then((response) => {

        let respData = this.getRespData(response)
        console.log(respData);

        switch (this.getResponseStatus(response)) {
            /**
             * Success
             */


          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if (respData?.price_variant?.rate && respData?.price_variant?.rate.length) {
              respData.selected_rate = respData.price_variant.rate[0]
            }

            let containsFlag = this.FO.data.relatedOrders.findIndex(item => {
              return item.order_id === respData?.order_id
            }) !== -1

            if (!containsFlag) {
              this.FO.addRelatedOrders(respData)
            }

            break
          }
            /**
             * Validation Error
             */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            this.scanErrorItem = this.scanValue
            break
          }
            /**
             * Undefined Error
             */
          default: {
            break
          }
        }

        //reset search input
        this.scanValue = ''
        document.querySelector('.fast-order-search .search-input__input').focus()

      })
    },

    changeVariant(item, val) {
      item.selected_rate = val
    },

    /**
     * Open order label by UUId
     */
    openLabelFBM(item) {
      let routeData = this.$router.resolve({
        path: this.$store.getters.GET_PATHS.getPdfFile + '/' + item?.uuid
      })

      window.open(routeData.href, '_blank');
    },

    getOldLabel(item) {
      let oldLabelIndex = Object.keys(item.old_labels).find(findItem => {
        return findItem
      })

      return item.old_labels[oldLabelIndex] || null
    }
  }
}
</script>

<style scoped>

</style>